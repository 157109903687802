@keyframes drawerButtonAnimationKeyframes {
  0% {
    background: transparent;
  }
  50% {
    background: grey;
  }
  100% {
    background: transparent;
  }
}

.drawerButtonAnimation {
  background: transparent;
  animation-name: drawerButtonAnimationKeyframes;
  animation-duration: 0.7s;
  animation-timing-function: ease-in-out;
}
